import { AvField, AvForm } from 'availity-reactstrap-validation'
// import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CardTitle,
  Col,
  Label,
  Card,
  Row,
  CardBody,
  Container,
} from 'reactstrap'
import { getCourseProviderDetails, updateCourseProvider } from 'store/actions'
import { useEffect } from 'react'
import Switch from 'react-switch'
import { OffSymbol, OnSymbol } from 'pages/Product/Crud/Create/Create'
import UseImageUploader from 'components/imageUploader/useImageUploader'
import { WithContext as ReactTags } from 'react-tag-input'
import MetaTag from 'components/Common/Meta-tag'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { useParams } from 'react-router-dom'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import { useHistory } from 'react-router-dom'

const CourseFormCrud = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { updateData } = useSelector(state => ({
    updateData: state.CourseProvider.courseProviderDetails,
  }))

  const [iconImage, setIconImage] = useState([])

  const [courseInputs, setCourseInputs] = useState([
    {
      fieldName: '',
      inputType: '',
      isRequired: false,
      isList: false,
      listItems: [],
      label: '',
    },
  ])

  const dragProps3 = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...courseInputs]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setCourseInputs(modifiedData)
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  }
  // const inputInputData = [
  //   'name',
  //   'email',
  //   'contactNumber',
  //   'whatsappNumber',
  //   'address',
  //   'message',
  //   'budget',
  // ]
  const fieldData = [
    'customField1',
    'customField2',
    'customField3',
    'customField4',
    'customField5',
  ]

  const typeData = ['number', 'string', 'boolean']

  const handleDeleteTag = (tag, input) => {
    const currentListItem = input?.listItems[tag]

    setCourseInputs(
      courseInputs?.map(courseInputItem =>
        courseInputItem === input
          ? {
              ...courseInputItem,
              listItems: (courseInputItem?.listItems || [])?.filter(
                i => i.id !== currentListItem?.id,
              ),
            }
          : courseInputItem,
      ),
    )
  }

  const handleAddition = (tag, input) => {
    setCourseInputs(
      courseInputs?.map(courseInputItem =>
        courseInputItem === input
          ? {
              ...courseInputItem,
              listItems: [...(courseInputItem?.listItems || []), tag],
            }
          : courseInputItem,
      ),
    )
  }

  // const handleDrag = (tag, currPos, newPos) => {
  //   const newTags = tags.slice()
  //   newTags.splice(currPos, 1)
  //   newTags.splice(newPos, 0, tag)
  //   // re-render
  //   setTags(newTags)
  //   const arrangedKeywords = newTags.map(a => a.text)
  //   setKeywords(arrangedKeywords)
  // }

  // const handleTagClick = index => {
  //   console.log('The tag at index ' + index + ' was clicked')
  // }
  const KeyCodes = {
    comma: 188,
    enter: 13,
  }
  const delimiters = [KeyCodes.comma, KeyCodes.enter]
  const handleValidSubmit = (e, v) => {
    const modifiedCourseInputs = courseInputs?.map(item => ({
      ...item,
      listItems: item?.listItems?.map(i => i.text),
    }))

    const CourseProvider = {
      // ...v,
      name: v?.name,
      email: v?.email,
      phone: v?.phone,
      courseFormData: {
        title: v?.title,
        description: v?.description,
        bannerImage: iconImage,
        courseInputs: modifiedCourseInputs,
      },

      // deductionType: select,
      // bannerImage:const [iconImage, setIconImage] = useState([]),
      // deductionType: select,
    }
    dispatch(updateCourseProvider(CourseProvider, updateData?._id, history))
  }
  useEffect(() => {
    setIconImage(updateData?.courseFormData?.bannerImage || [])

    const modifiedCourseInputs = updateData?.courseFormData?.courseInputs?.map(
      item => ({
        ...item,
        listItems: item?.listItems?.map(i => ({ id: i, text: i })),
      }),
    )

    setCourseInputs(
      updateData?.courseFormData?.courseInputs.length > 0
        ? modifiedCourseInputs
        : [
            {
              fieldName: '',
              inputType: '',
              isRequired: false,
              isList: false,
              listItems: [],
              label: '',
            },
          ],
    )

    // setCourseInputs(
    //   updateData?.courseFormData?.courseInputs?.map(courseInputItem =>[
    //           ...courseInputItem,
    //   ]
    //   ),
    // )

    // setSelect(updateData?.deductionType)
    // setSelectType(updateData?.type)
    // setIsList(updateData?.isList)
    // setIsRequired(updateData?.isRequired)
  }, [updateData])

  useEffect(() => {
    dispatch(getCourseProviderDetails(params?.id))
  }, [])

  return (
    <>
      <MetaTag title="Course Provider" />

      <div className="page-content">
        <Breadcrumbs title="Update " breadcrumbItem="Update Course Provider" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        {' '}
                        <AvField
                          name="name"
                          type="text"
                          label="Name"
                          placeholder="Enter name"
                          value={updateData?.name}
                          className="mb-3"
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="phone"
                          type="text"
                          label="Phone"
                          placeholder="Enter Phone Number"
                          value={updateData?.phone}
                          className="mb-3"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="email"
                          type="email"
                          label="Email"
                          placeholder="Enter email"
                          className="mb-3"
                          value={updateData?.email}
                        />
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="title"
                          type="text"
                          label="Title"
                          placeholder="Enter title"
                          className="mb-3"
                          value={updateData?.courseFormData?.title}
                        />
                      </Col>
                    </Row>

                    <Col lg={6}>
                      <AvField
                        name="description"
                        type="text"
                        label="Description"
                        placeholder="Enter description"
                        className="mb-3"
                        value={updateData?.courseFormData?.description}
                      />
                    </Col>
                    {/* <Row>
                      <Col lg={6}>
                        <AvField
                          name="label"
                          type="text"
                          placeholder="Enter label"
                          className="mb-3"
                          value={
                            updateData?.courseFormData?.courseInputs?.label
                          }
                        />
                      </Col>
                    </Row> */}
                    <Row>
                      <Label> Banner Image</Label>
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={iconImage}
                        setImagesPreview={setIconImage}
                        uploadUrl={'/categories'}
                      />
                    </Row>

                    <div className="d-flex mt-3">
                      <h5 className="my-2 text_deals_green">
                        Course Input Setup
                      </h5>
                      <button
                        className="mx-2 btn btn-success d-flex align-items-center"
                        onClick={e => {
                          e.preventDefault()
                          setCourseInputs(currentCourseInput => [
                            ...currentCourseInput,
                            {
                              value: '',
                              isRequired: false,
                            },
                          ])
                        }}
                      >
                        ADD{' '}
                        <i
                          className="bx bx-plus mx-1"
                          style={{ fontSize: '18px' }}
                        ></i>
                      </button>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="m-0 pt-2 text-danger">
                        (The Name, Phone, and Email inputs have already been
                        established, so there is no need for you to create them)
                      </p>
                    </div>
                    <Row>
                      <Col
                        className="col-12 p-3 mt-1 mx-3"
                        // style={{
                        //   border: '1px solid #f2f2f2',
                        //   borderRadius: '15px',
                        // }}
                      >
                        <div className="col-12 mb-4">
                          {/* <Row>
                            <Col md="4" className="m-2">
                              <button
                                className="mx-2 btn btn-success d-flex align-items-center"
                                onClick={e => {
                                  e.preventDefault()
                                  setCourseInputs(currentCourseInput => [
                                    ...currentCourseInput,
                                    {
                                      value: '',
                                      isRequired: false,
                                    },
                                  ])
                                }}
                              >
                                ADD{' '}
                                <i
                                  className="bx bx-plus mx-1"
                                  style={{ fontSize: '18px' }}
                                ></i>
                              </button>
                            </Col>
                          </Row> */}

                          <Col md="12" className="mx-1">
                            <ReactDragListView {...dragProps3}>
                              {(courseInputs || [])?.map((input, index) => {
                                return (
                                  // <tr key={index} className="d-flex w-100 mb-4">
                                  <div
                                    className="drag-pointer grabbable mb-2"
                                    key={index}
                                    style={{
                                      border: '1px solid #f2f2f2',

                                      padding: '1rem',
                                      borderRadius: '15px',
                                      // position: 'absolute',
                                    }}
                                  >
                                    <div className="d-flex justify-content-end">
                                      <button
                                        className="mx-2 btn"
                                        onClick={e => {
                                          e.preventDefault()
                                          setCourseInputs(currentCourseInput =>
                                            currentCourseInput.filter(
                                              x => x !== input,
                                            ),
                                          )
                                        }}
                                      >
                                        <i
                                          className="bx bx-trash text-danger"
                                          style={{
                                            fontSize: '20px',
                                          }}
                                        ></i>
                                      </button>
                                    </div>
                                    <Row lg={12}>
                                      <Col className="col-3">
                                        <AvField
                                          type="text"
                                          name="input.label"
                                          label="Label"
                                          value={input.label}
                                          onChange={e => {
                                            const label = e.target.value
                                            setCourseInputs(
                                              currentCourseInput =>
                                                currentCourseInput.map(x =>
                                                  x === input
                                                    ? {
                                                        ...x,
                                                        label,
                                                      }
                                                    : x,
                                                ),
                                            )
                                          }}
                                          placeholder="Enter label"
                                          // helpMessage="Select a Shipping Input"
                                        ></AvField>
                                      </Col>

                                      <Col className="col-3">
                                        <AvField
                                          type="select"
                                          name="input.name"
                                          label="Field Name"
                                          value={input.fieldName}
                                          onChange={e => {
                                            const fieldName = e.target.value
                                            setCourseInputs(
                                              currentCourseInput =>
                                                currentCourseInput.map(x =>
                                                  x === input
                                                    ? {
                                                        ...x,
                                                        fieldName,
                                                      }
                                                    : x,
                                                ),
                                            )
                                          }}

                                          // helpMessage="Select a Shipping Input"
                                        >
                                          <option>Select a Field Name</option>
                                          {fieldData?.map((item, key) => (
                                            <option key={key} value={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </AvField>
                                      </Col>
                                      <Col className="col-3">
                                        <AvField
                                          type="select"
                                          name="input.name"
                                          label="Type"
                                          value={input.inputType}
                                          onChange={e => {
                                            const inputType = e.target.value
                                            setCourseInputs(
                                              currentCourseInput =>
                                                currentCourseInput.map(x =>
                                                  x === input
                                                    ? {
                                                        ...x,
                                                        inputType,
                                                      }
                                                    : x,
                                                ),
                                            )
                                          }}

                                          // helpMessage="Select a Shipping Input"
                                        >
                                          <option>Select a type </option>
                                          {typeData?.map((item, key) => (
                                            <option key={key} value={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </AvField>
                                      </Col>
                                      <Col className="col-3">
                                        <div className="align-items-center d-flex mt-2">
                                          <Label
                                            check
                                            for="checkbox"
                                            className="pe-2"
                                          >
                                            {' '}
                                            Is Required
                                          </Label>
                                          <Switch
                                            uncheckedIcon={<OffSymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2"
                                            onColor="#626ed4"
                                            height={30} // Set the height of the Switch
                                            width={80}
                                            onChange={e => {
                                              setCourseInputs(
                                                currentCourseInput =>
                                                  currentCourseInput.map(x =>
                                                    x === input
                                                      ? {
                                                          ...x,
                                                          isRequired: e,
                                                        }
                                                      : x,
                                                  ),
                                              )
                                            }}
                                            checked={input?.isRequired}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-4">
                                      <Col className="col-3">
                                        <div className="align-items-center d-flex">
                                          <Label
                                            check
                                            for="checkbox"
                                            className="pe-2"
                                          >
                                            {' '}
                                            Is List
                                          </Label>
                                          <Switch
                                            uncheckedIcon={<OffSymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2"
                                            onColor="#626ed4"
                                            height={30} // Set the height of the Switch
                                            width={80}
                                            onChange={e => {
                                              setCourseInputs(
                                                currentCourseInput =>
                                                  currentCourseInput.map(x =>
                                                    x === input
                                                      ? {
                                                          ...x,
                                                          isList: e,
                                                        }
                                                      : x,
                                                  ),
                                              )
                                            }}
                                            checked={input?.isList}
                                          />
                                        </div>
                                      </Col>
                                      {input.isList && (
                                        <>
                                          <Col className="col-9">
                                            <div className=" col-12 row mb-4">
                                              <Label
                                                htmlFor="horizontal-firstname-Input"
                                                className="col-sm-2 col-form-label"
                                              >
                                                {' '}
                                                List Items:
                                              </Label>
                                              <Col sm={10}>
                                                <ReactTags
                                                  tags={
                                                    courseInputs[index]
                                                      ?.listItems
                                                  }
                                                  allowDragDrop={false}
                                                  placeholder="Type TEXT and enter"
                                                  // suggestions={suggestions}
                                                  delimiters={delimiters}
                                                  handleDelete={e =>
                                                    handleDeleteTag(e, input)
                                                  }
                                                  handleAddition={e => {
                                                    handleAddition(e, input)
                                                  }}
                                                  // handleDrag={handleDrag}
                                                  // handleTagClick={
                                                  //   handleTagClick
                                                  // }
                                                  // inputFieldPosition="inline"
                                                  autocomplete
                                                  allowUnique
                                                  inline
                                                />
                                              </Col>
                                            </div>
                                            {/* </AvField> */}
                                          </Col>
                                        </>
                                      )}
                                    </Row>
                                  </div>
                                  // </tr>
                                )
                              })}
                            </ReactDragListView>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row xl="12">
                      <Col xl="12">
                        <Card>
                          <CardBody>
                            <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="col-12 btn btn-success save-customer"
                                  >
                                    {/* {loading && (
                                      <>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                      </>
                                    )} */}
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CourseFormCrud
// CourseCrud.propTypes = {
//   updateData: PropTypes.any,
// }
