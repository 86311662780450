import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

//actions
import { getCourseRegistrations, updateCourseRegistration } from 'store/actions'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
// import DeleteModal from 'components/Common/DeleteModal'
// import CourseCrud from './Crud/CourseProviderCrud'
import { Markup } from 'interweave'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'

const CourseRegistration = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')

  const { loading, courseRegistrations } = useSelector(state => ({
    loading: state.CourseRegistration.loading,
    courseRegistrations: state.CourseRegistration.courseRegistrations,
  }))

  const role = sessionStorage.getItem('role')

  const totalPages = Math.ceil(courseRegistrations?.total / 10)
  const pages = range(1, totalPages + 1)

  // const [sort, setSort] = useState('all')
  const [status, setStatus] = useState()

  useEffect(() => {
    dispatch(getCourseRegistrations(page, '', 10, searchText))
  }, [dispatch, page, searchText])

  const handleStatusChange = (v, id) => {
    setStatus(v)
    dispatch(
      updateCourseRegistration(
        {
          status: status ? 'registered' : 'processing',
        },
        id,
      ),
    )
  }

  // const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  // const handleDelete = id => {
  //   setIsOpen({ status: true, id: id })
  // }

  // const handleDeleteEvent = () => {
  //   setIsOpen({ ...isOpen, status: false })
  // }

  const columns = [
    {
      dataField: 'order',
      text: 'Order',
      sort: true,
    },
    {
      dataField: 'product.primaryLang.name',
      text: 'Product',
      sort: true,
    },
    {
      dataField: 'courseProvider.name',
      text: role === 'admin' && 'Course Provider',
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    // {
    //   dataField: "imageData",
    //   filterText: "image",
    //   text: "Image",
    //   sort: true,
    // },

    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      sort: true,
    },
    // {
    //   dataField: "activeData",
    //   text: "Status",
    // },
    // {
    //   dataField: "isPublished",
    //   text: "Published",
    // },
    // {
    //   dataField: "isHighlighted",
    //   text: "Type",
    // },

    {
      dataField: 'action',
      text: 'Action',
    },
  ]

  const tableData = map(
    courseRegistrations?.courseRegistrations,
    (item, index) => ({
      ...item,
      no: index,
      order: (
        <Link to={`/order/${item?.order?._id}`}>{item?.order?.orderId}</Link>
      ),
      status: (
        <>
          <div className="form-check form-switch form-switch-md me-4">
            <label
              className={`form-check-label ${
                item?.status === 'registered' ? 'text-success' : 'text-info'
              }`}
              htmlFor="statusSwitch"
            >
              {item?.status === 'registered' ? 'Registered' : 'Processing'}
            </label>
            <input
              type="checkbox"
              className={
                'form-check-input ' +
                `${item?.status === 'registered' && 'bg-success'}`
              }
              id="statusSwitch"
              style={{
                border: item?.status === 'registered' && 'none',
              }}
              onChange={() => {
                handleStatusChange(
                  item?.status === 'registered' ? false : true,
                  item?._id,
                )
              }}
              checked={item?.status === 'registered'}
            />
          </div>
        </>
      ),
      // imageData: (
      //   <>
      //     <img
      //       src={
      //         item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
      //       }
      //       width="50px"
      //     />
      //   </>
      // ),
      // title: (
      //   <div className="d-flex algin-items-center text-start   justify-content-start ">
      //     <p className="mb-0 mt-1">{item?.title}</p>
      //     {sort === 'all' && (
      //       <>
      //         {item?.isPublished && (
      //           <Badge
      //             className={'font-size-11 mx-2 p-2 badge-soft-success '}
      //             style={{
      //               width: 'fit-content',
      //             }}
      //             pill
      //           >
      //             Published
      //           </Badge>
      //         )}
      //         {item?.isHighlighted && (
      //           <Badge
      //             className={'font-size-11 me-2 p-2 badge-soft-info  '}
      //             style={{
      //               width: 'fit-content',
      //             }}
      //             pill
      //           >
      //             Highlighted
      //           </Badge>
      //         )}
      //       </>
      //     )}
      //   </div>
      // ),
      content: (
        <div
          className="text-break-spaces position-relative"
          style={{ maxWidth: 350 }}
        >
          <Markup content={item?.content?.slice(0, 140)} className="markup" />
          <span
            style={{
              position: 'absolute',
              bottom: 0,
              right: 100,
            }}
          >
            {' '}
            {item?.content?.length > 140 ? '...' : ''}
          </span>
        </div>
      ),

      action: (
        <div>
          <Link
            to={`/courseRegistrations/${item?._id}`}
            className="btn-md me-3"
            id={'view'}
          >
            <i
              className="mdi mdi-eye"
              style={{ color: 'green', fontSize: '20px' }}
            />
          </Link>
          <UncontrolledTooltip
            id="downloadTip"
            target={'view'}
            placement="bottom"
          >
            View
          </UncontrolledTooltip>
          {/* <Link
            to={{ pathname: `/courseForm/update/${item?._id}` }}
            className="btn-md"
           
          >
            <i className="bx bx-edit me-2 " style={{ fontSize: '1.3rem' }} />
          </Link> */}
          {/* <Button
            id={`Delete${index}`}
            type="button"
            color="white"
            className="ms-1 btn "
            onClick={() => {
              handleDelete(item._id)
            }}
          >
            <i
              className="bx bx-trash me-2"
              style={{ color: 'red', marginBottom: '10px', fontSize: '1.3rem' }}
            />
          </Button> */}
        </div>
      ),
      createdAt: moment(item.createdAt).format('lll'),
    }),
  )

  const debounceBlogSearch = debounce(value => setSearchText(value), 600)

  // const listChange = sortValue => {
  //   setSort(sortValue)
  // }

  // const headerTap = [
  //   {
  //     value: 'all',
  //     title: 'All',
  //   },

  //   {
  //     value: 'service',
  //     title: 'SERVICE',
  //   },
  //   {
  //     value: 'tds',
  //     title: 'TDS ',
  //   },
  // ]

  return (
    <React.Fragment>
      <Row>
        {/* <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        /> */}
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      {/* <Col lg={5} className="mb-3">
                        <div
                          className="btn-group mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {headerTap?.map((tap, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnRadio"
                                id={`btnRadio${tap?.value}`}
                                autoComplete="off"
                                checked={tap.value === sort ? true : false}
                                onChange={() => listChange(tap?.value)}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={`btnRadio${tap?.value}`}
                              >
                                {tap?.title}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col> */}
                      <Col lg={4}>
                        <div className="search-box w-100 ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceBlogSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      {/* <Col lg={3}>
                        <div className="text-sm-end">
                          <Link
                            to={{
                              pathname: '/courseForm/create/new',
                            }}
                          >
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Course Provider
                            </Button>
                          </Link>
                        </div>
                      </Col> */}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {courseRegistrations?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Course Providers !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CourseRegistration
