import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'
import SidebarContent from './SidebarContent'

import { Link } from 'react-router-dom'

//redux
import { useSelector } from 'react-redux'
import { LocalLogo, LocalLogo2 } from 'assets/images'

const Sidebar = props => {
  const { settings, sideBarSm } = useSelector(state => ({
    settings: state.Settings.settings,
    sideBarSm: state.Layout.sideBarSm,
  }))

  return (
    <React.Fragment>
      <div
        className="vertical-menu"
        // style={{ backgroundColor: primaryColor || "#ffff" }}
      >
        <div className="navbar-brand-box ">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img
                src={sideBarSm === false ? LocalLogo2 : LocalLogo2}
                alt="company logo"
                height="20"
              />
            </span>
            <span className="logo-lg">
              <img
                src={settings?.company?.logo[0]?.ur ?? LocalLogo}
                alt=""
                height={40}
                width={120}
                style={{
                  objectFit: 'contain',
                }}
              />
            </span>
          </Link>

          <Link to="/" className="logo logo-light"></Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== 'condensed' ? (
            <SidebarContent props />
          ) : (
            <SidebarContent />
          )}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {},
)(withRouter(withTranslation()(Sidebar)))
