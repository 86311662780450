import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

//actions
import { deleteCourseProvider, getCourseProviders } from 'store/actions'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
import DeleteModal from 'components/Common/DeleteModal'
// import CourseCrud from './Crud/CourseProviderCrud'
// import { Markup } from 'interweave'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import CourseProviderModal from './Crud/modal'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const CourseProviderList = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')

  const { loading, courseProviders } = useSelector(state => ({
    loading: state.CourseProvider.loading,
    courseProviders: state.CourseProvider.courseProviders,
  }))

  const [courseProviderModal, setCourseProviderModal] = useState({
    status: false,
    data: {},
  })

  const totalPages = Math.ceil(courseProviders?.total / 10)
  const pages = range(1, totalPages + 1)

  // const [sort, setSort] = useState('all')

  useEffect(() => {
    dispatch(getCourseProviders(page, '', 10, searchText))
  }, [dispatch, page, searchText])

  const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteCourseProvider(isOpen?.id))
  }

  const columns = [
    {
      dataField: 'name',
      text: 'name',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'email',
      sort: true,
    },

    {
      dataField: 'phone',
      text: 'phone',
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      sort: true,
    },

    {
      dataField: 'action',
      text: 'Action',
    },
  ]

  const tableData = map(courseProviders?.courseProviders, (item, index) => ({
    ...item,
    no: index,
    createdAt: moment(item.createdAt).format('lll'),
    action: (
      <div>
        <Link to={`/courseForm/update/${item?._id}`}>
          <Button
            id={`Edit`}
            type="button"
            color="white"
            className="ms-1 btn "
            // onClick={() => {
            //   setCourseProviderModal({ status: true, data: item })
            // }}
          >
            <i
              className="bx bx-edit me-2 "
              style={{
                color: 'blue',
                marginBottom: '10px',
                fontSize: '1.3rem',
              }}
            />
          </Button>
        </Link>
        <Button
          id={`Delete`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: 'red', marginBottom: '10px', fontSize: '1.3rem' }}
          />
        </Button>
        <UncontrolledTooltip placement="bottom" target={`Edit`}>
          Edit
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`Delete`}>
          Delete
        </UncontrolledTooltip>
      </div>
    ),
  }))

  const debounceBlogSearch = debounce(value => setSearchText(value), 600)

  // const listChange = sortValue => {
  //   setSort(sortValue)
  // }

  // const headerTap = [
  //   {
  //     value: 'all',
  //     title: 'All',
  //   },

  //   {
  //     value: 'service',
  //     title: 'SERVICE',
  //   },
  //   {
  //     value: 'tds',
  //     title: 'TDS ',
  //   },
  // ]

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <CourseProviderModal
          onCloseClick={() =>
            setCourseProviderModal({ status: false, data: {} })
          }
          show={courseProviderModal?.status}
          updateData={courseProviderModal?.data}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      {/* <Col lg={5} className="mb-3">
                        <div
                          className="btn-group mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {headerTap?.map((tap, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnRadio"
                                id={`btnRadio${tap?.value}`}
                                autoComplete="off"
                                checked={tap.value === sort ? true : false}
                                onChange={() => listChange(tap?.value)}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={`btnRadio${tap?.value}`}
                              >
                                {tap?.title}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col> */}
                      <Col lg={4}>
                        <div className="search-box w-100 ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceBlogSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={8}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() =>
                              setCourseProviderModal({ status: true })
                            }
                          >
                            <i className="mdi mdi-plus me-1" />
                            Create Course Provider
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {courseProviders?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Course Providers !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CourseProviderList
