import React from 'react'
import { Redirect } from 'react-router-dom'

// Profile
import UserProfile from '../pages/Authentication/user-profile'

// Authentication related pages
import Login from 'pages/Authentication/Login'
import LoginOtp from 'pages/Authentication/LoginOtp'

import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'
import ForgetPwd from '../pages/Authentication/ForgetPassword'

// Dashboard
import Dashboard from '../pages/Dashboard/index'

//Notifications
import Notifications from 'pages/Notification/index'

//Product
import Product from 'pages/Product/index'
import DeletedProduct from 'pages/Product/Deleted/index'
import ProductDetails from 'pages/Product/SingleView/index'
import UpdateProduct from 'pages/Product/Crud/Update/Update'
import CreateProduct from 'pages/Product/Crud/Create/Create'

//Banner
import Banners from 'pages/Banner/index'
import UpdateBanner from 'pages/Banner/Crud/Update'
import CreateBanner from 'pages/Banner/Crud/Create'

//User
import User from 'pages/User/index'
import UserDetails from 'pages/User/SingleView/index'
import UpdateUser from 'pages/User/Crud/Update/Update'

//Order
import Orders from 'pages/Order/index'
import OrderDetails from 'pages/Order/SingleView/index'
import StarredOrders from 'pages/Order/StarredOrder/index'
import CancelledOrders from 'pages/Order/CancelledOrder/index'
import ReturnedOrders from 'pages/Order/ReturnedOrder/index'

// Settings
import Banner from 'pages/Settings/index'
import UpdateSettings from 'pages/Settings/Crud/Update/Update'

//Revenue
import Revenues from 'pages/Revenue/index'
import RevenueDetail from 'pages/Revenue/shopDetail/index'

//Errors
//404
import Pages404 from 'pages/error/pages-404'
import Classification from 'pages/Category/index'
import Collections from 'pages/collections'
import CrateCollections from 'pages/collections/Crud/Create'
import UpdateCollections from 'pages/collections/Crud/Update'
import Groups from 'pages/groups-home'
import CrateGroups from 'pages/groups-home/Crud/Create'
import UpdateGroups from 'pages/groups-home/Crud/Update'
import Coupons from 'pages/coupon'
import CreateCoupon from 'pages/coupon/Crud/Create'
import UpdateCoupons from 'pages/coupon/Crud/Update'
import AreaList from 'pages/Area'
import CreateCategory from 'pages/Category/Create/Create'
import UpdateCategory from 'pages/Category/Update/Update'
import UpdateProductStock from 'pages/Product/stockUpdate/Update'
import CreateAdminOrder from 'pages/Order/Crud/Create'
import ProductPages from 'pages/product-pages'
import CratePages from 'pages/product-pages/Crud/Create'
import UpdatePages from 'pages/product-pages/Crud/Update'
import CreatePromoter from 'pages/Promoter/Crud/Create'
import UpdatePromoter from 'pages/Promoter/Crud/Update'
import PromoterDetails from 'pages/Promoter/SingleView'
import Promoters from 'pages/Promoter'
import AllPageView from 'pages/Promoter/Sub-Contents/allPageView'
import AllEarnings from 'pages/Promoter/Sub-Contents/allEarnings'
import FormattedEarnings from 'pages/Promoter/Sub-Contents/FormattedEarnings'

import ProductPerformance from 'pages/Performance'
import PageViews from 'pages/Promoter/Dashboard/PageView'
import PromoterEarnings from 'pages/Promoter/Dashboard/Earnings'
import Products from 'pages/Promoter/Dashboard/Products'
import PromoterProductDetails from 'pages/Promoter/Dashboard/ProductDetails'
import Reviews from 'pages/Reviews'
import Blogs from 'pages/Blogs'
import Transactions from 'pages/Transactions'
import SupplyChain from 'pages/SupplyChain'
import CreateSupplyChain from 'pages/SupplyChain/Crud/Create'
import UpdateSupplyChain from 'pages/SupplyChain/Crud/Update'
import PromotersDashboard from 'pages/PromoterPanel'
import ChainPromoter from 'pages/ChainPromoter'
import ChainPromoterDetail from 'pages/ChainPromoter/SingleView'
import SupplyChainTransaction from 'pages/PromoterTransaction'
import luckyDraws from 'pages/luckyDraw'
import withdrawalRequests from 'pages/WithdrawalRequest'
import LuckyDrawDetailsDetails from 'pages/luckyDraw/singleView/Index'
import SupplyChainProductTable from 'pages/PromoterPanel/SupplyChainProduct'
import SupplyChainTree from 'pages/ChainTree/ChainTree'
import NotificationPage from 'pages/Notification'
import Deductions from 'pages/Deduction'
import CourseProviders from 'pages/courseProvider'
import CourseRegistrations from 'pages/courseRegistration'
import CourseFormCrud from 'pages/courseForm/Crud/Create'
import CourseRegistrationDetail from 'pages/courseRegistration/SingleView/Details'

const commonRoute = [
  { path: '/dashboard', component: Dashboard },

  { path: '/logout', component: Logout },

  {
    path: '/',
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const superAdminRoutes = [
  // products
  { path: '/products', component: Product },
  { path: '/products/deleted', component: DeletedProduct },
  { path: '/product/:id', component: ProductDetails },
  { path: '/product/create/new', component: CreateProduct },
  { path: '/product/update/:id', component: UpdateProduct },
  { path: '/stockUpdate', component: UpdateProductStock },

  //courseprovider
  { path: '/courseProviders', component: CourseProviders },
  { path: '/courseForm/update/:id', component: CourseFormCrud },
  { path: '/courseRegistrations', component: CourseRegistrations },
  { path: '/courseRegistrations/:id', component: CourseRegistrationDetail },

  // shopProducts
  // { path: "/shop-products", component: ShopProduct },
  // { path: "/shop-products/deleted", component: DeletedShopProduct },
  // { path: "/shop-product/:id", component: ShopProductDetails },
  // { path: "/shop-product/create/new", component: CreateShopProduct },
  // { path: "/shop-product/update/:id", component: UpdateShopProduct },

  //stores
  // { path: "/stores", component: Store },
  // { path: "/stores/quarantined", component: QuarantinedStore },
  // { path: "/stores/unapproved", component: UnapprovedStore },
  // { path: "/store/:id", component: StoreDetails },
  // { path: "/store/create/new", component: CreateStore },
  // { path: "/store/update/:id", component: UpdateStore },

  // //shops
  // { path: "/shops", component: Shop },
  // { path: "/shops/quarantined", component: QuarantinedShop },
  // { path: "/shops/unapproved", component: UnapprovedShop },
  // { path: "/shop/:id", component: ShopDetails },
  // { path: "/shop/create/new", component: CreateShop },
  // { path: "/shop/update/:id", component: UpdateShop },

  //Classifications
  { path: '/classifications', component: Classification },
  { path: '/category/create/new', component: CreateCategory },
  { path: '/category/update/:id', component: UpdateCategory },

  //supply-chain
  { path: '/supply-chain', component: SupplyChain },
  { path: '/supply-chain/create/new', component: CreateSupplyChain },
  { path: '/supply-chain/update/:id', component: UpdateSupplyChain },
  { path: '/supply-chain-products', component: SupplyChainProductTable },

  //UpdateLuckyDraw
  { path: '/lucky-draw', component: luckyDraws, isPromoter: true },
  { path: '/lucky-draw-details/:id', component: LuckyDrawDetailsDetails },

  //withdrawalRequests
  {
    path: '/withdrawal-requests',
    component: withdrawalRequests,
    isPromoter: true,
  },

  //chain-promoter
  { path: '/chain-promoter', component: ChainPromoter, isPromoter: true },
  {
    path: '/chain-promoter/:id',
    component: ChainPromoterDetail,
  },

  //Promoter Transactions
  {
    path: '/promoter-transactions',
    component: SupplyChainTransaction,
    isPromoter: true,
  },

  //collections
  { path: '/collections', component: Collections },
  { path: '/collections/create/new', component: CrateCollections },
  { path: '/collections/update/:id', component: UpdateCollections },

  //groups
  { path: '/groups', component: Groups },
  { path: '/groups/create/new', component: CrateGroups },
  { path: '/groups/update/:id', component: UpdateGroups },

  //pages
  { path: '/pages/all', component: ProductPages },
  { path: '/pages/product/create', component: CratePages },
  { path: '/pages/product/:id', component: UpdatePages },

  //coupons
  { path: '/coupons', component: Coupons },
  { path: '/coupons/create/new', component: CreateCoupon },
  { path: '/coupons/update/:id', component: UpdateCoupons },

  //area
  { path: '/area', component: AreaList },

  // Users
  { path: '/users', component: User },
  { path: '/users/:id', component: UserDetails },
  { path: '/user/update/:id', component: UpdateUser },

  // Orders
  { path: '/orders', component: Orders },
  { path: '/orders/new', component: CreateAdminOrder },
  { path: '/order/:id', component: OrderDetails },
  { path: '/orders/starred', component: StarredOrders },
  { path: '/orders/cancelled', component: CancelledOrders },
  { path: '/orders/returned', component: ReturnedOrders },

  //Dashboard
  { path: '/dashboard', component: Dashboard },

  //promoter Dashboard
  { path: '/promoter-dashboard', component: PromotersDashboard },

  //profile
  { path: '/profile', component: UserProfile },

  //Notifications
  { path: '/notifications', component: Notifications },

  //Banner
  { path: '/banners', component: Banners },
  { path: '/banner/create/new', component: CreateBanner },
  { path: '/banner/update/:id', component: UpdateBanner },

  //notifications
  { path: '/notifications', component: NotificationPage, isPromoter: true },

  //review
  { path: '/reviews', component: Reviews },

  { path: '/blogs', component: Blogs },
  //deduction
  { path: '/deductions', component: Deductions },
  // transactions
  { path: '/transactions', component: Transactions },

  //Settings
  { path: '/super-admin/settings', component: Banner },
  { path: '/super-admin/settings/:id', component: UpdateSettings },

  //Revenues
  { path: '/revenues', component: Revenues },
  { path: '/revenue/:id', component: RevenueDetail },

  //Performance
  { path: '/performance/product', component: ProductPerformance },

  // promoter
  { path: '/promoters', component: Promoters },
  { path: '/promoter/:id', component: PromoterDetails },
  { path: '/promoter/create/new', component: CreatePromoter },
  { path: '/promoter/update/:id', component: UpdatePromoter },

  { path: '/all-page-view', component: AllPageView },
  { path: '/all-earnings', component: AllEarnings },
  { path: '/formatted-earnings', component: FormattedEarnings },

  { path: '/supply-chain-tree/:id', component: SupplyChainTree },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
]

const promoterRoutes = [
  ...superAdminRoutes?.filter(i => i.isPromoter),
  { path: '/dashboard', component: Dashboard },

  { path: '/promoter-page-view', component: PageViews },
  { path: '/promoter-earnings', component: PromoterEarnings },
  { path: '/promoter/products', component: Products },
  { path: `/promoter/product/:id`, component: PromoterProductDetails },

  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
]
const courseProviderRoutes = [
  { path: '/dashboard', component: Dashboard },

  { path: '/courseRegistrations', component: CourseRegistrations },

  { path: '/courseForm/update', component: CourseFormCrud },
  { path: '/courseRegistrations/:id', component: CourseRegistrationDetail },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
]
const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/login-otp', component: LoginOtp },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  { path: '/404', component: Pages404 },
]

export {
  publicRoutes,
  superAdminRoutes,
  promoterRoutes,
  courseProviderRoutes,
  commonRoute,
}
