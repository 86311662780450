import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Badge, Card, CardBody, UncontrolledTooltip } from 'reactstrap'

function InfluencedPromoter() {
  const { orderDetails } = useSelector(state => ({
    orderDetails: state.Orders.orderDetails,
  }))

  return (
    <Card>
      <CardBody>
        <div className="mb-1 d-flex align-items-start justify-content-between">
          <p className="mb-1">Influenced Promoter</p>

          <Badge
            className={'font-size-13 badge-soft-success'}
            id="orderDetailsRefId"
            color=""
          >
            {orderDetails?.ref_id}
          </Badge>
          <UncontrolledTooltip target={'orderDetailsRefId'}>
            Ref Id
          </UncontrolledTooltip>
        </div>
        <h6 className="mb-2 mx-1">{'userDetails?.name'}</h6>
        <p className="mb-0 d-flex align-items-center">
          <i className="bx bx-phone-call text-success font-size-16 mx-1"></i>
          {'userDetails?.phone'}
        </p>
        <p className="mb-0 mx-1 d-flex align-items-center text-warning">
          Joined on&nbsp;
          {moment(orderDetails?.createdAt).format('ll')}
        </p>
      </CardBody>
    </Card>
  )
}

export default InfluencedPromoter
