import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
// import { useHistory } from 'react-router'
import {
  // Button,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  TabPane,
  TabContent,
  Spinner,
  Badge,
  Table,
} from 'reactstrap'
// import classnames from 'classnames'

import Breadcrumbs from 'components/Common/Breadcrumb'

//actions

//redux
import { useSelector, useDispatch } from 'react-redux'
import MetaTag from 'components/Common/Meta-tag'
import {
  getCourseRegistrationDetails,
  // updateCourseRegistration,
} from 'store/actions'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const CourseRegistrationDetail = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { courseRegistrationDetails, loading } = useSelector(state => ({
    courseRegistrationDetails:
      state.CourseRegistration.courseRegistrationDetails,
    loading: state.CourseRegistration.loading,
  }))

  // const [status, setStatus] = useState()

  const role = sessionStorage.getItem('role')

  useEffect(() => {
    dispatch(getCourseRegistrationDetails(params.id))
  }, [dispatch])

  const [newInputData, setNewInputData] = useState([])

  useEffect(() => {
    setNewInputData([])
    courseRegistrationDetails?.courseProvider?.courseFormData?.courseInputs?.map(
      item =>
        [1, 2, 3, 4, 5]?.map(i => {
          switch (item.fieldName) {
            case `customField${i}`:
              setNewInputData(prevData => [
                ...prevData,
                {
                  title: item?.label,
                  value: courseRegistrationDetails[`customField${i}`],
                  id: item?._id + i,
                },
              ])
              break

            default:
            // Handle default case if needed
          }
        }),
    )
  }, [courseRegistrationDetails])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="Course Registration Details" />

        <Container fluid>
          <Breadcrumbs
            title="Course Registration"
            breadcrumbItem="Course Registration Details"
          />
          {loading ? (
            <Spinner color="secondary" className="d-block m-auto" />
          ) : (
            <>
              <Row>
                <Col lg={8}>
                  <Card>
                    <CardBody>
                      <div className="d-flex align-items-center justify-content-end">
                        <p className="pe-2 m-0 fs-6">
                          Status :
                          <Badge
                            className={`${
                              courseRegistrationDetails?.status === 'registered'
                                ? 'badge-soft-success'
                                : 'badge-soft-info'
                            } mx-2`}
                          >
                            {courseRegistrationDetails?.status === 'registered'
                              ? 'Registered'
                              : 'Processing'}
                          </Badge>
                        </p>
                      </div>
                      {role === 'admin' && (
                        <p className="text-primary">
                          <span className="text-black">Provider</span> {' : '}
                          <Link
                            to={`/courseForm/update/${courseRegistrationDetails?.courseProvider?._id}`}
                          >
                            {courseRegistrationDetails?.courseProvider?.name}
                          </Link>
                        </p>
                      )}
                      <h4 className="pb-2">
                        {courseRegistrationDetails?.name}
                      </h4>
                      <div className="d-flex align-items-center mb-2">
                        <i className="bx bx-phone text-primary pe-2"> </i>
                        <p className="m-0 fs-6">
                          {courseRegistrationDetails?.phone}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <i className="bx bx-mail-send text-primary pe-2"> </i>
                        <p className="m-0 fs-6">
                          {courseRegistrationDetails?.email}
                        </p>
                      </div>
                      <Col md="6">
                        <div className="table-responsive mt-3">
                          <Table className="table mb-0 myOrderTable ">
                            <tbody>
                              {newInputData?.map((item, key) => (
                                <tr style={{ borderBottom: '#fff' }} key={key}>
                                  <td
                                    style={{ verticalAlign: 'middle' }}
                                    className="py-3"
                                  >
                                    {item?.title}
                                  </td>
                                  <td>:</td>
                                  <td
                                    className="text-center"
                                    style={{ verticalAlign: 'middle' }}
                                  >
                                    <span className="text-black  text-end">
                                      {item?.value}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      {/* {newInputData?.map((item, key) => (
                        <div key={key} className="d-flex align-items-center">
                          <p className="mb-1 me-2">{item?.title}</p>
                          <span>{item?.value && ' : '}</span>
                          <p className="mb-1 mx-3">{item?.value}</p>
                        </div>
                      ))} */}
                      {/* <div className="form-check form-switch form-switch-md me-4">
                          <label
                            className={`form-check-label ${
                              courseRegistrationDetails?.status === 'registered'
                                ? 'text-success'
                                : 'text-info'
                            }`}
                            htmlFor="statusSwitch"
                          >
                            {courseRegistrationDetails?.status === 'registered'
                              ? 'Registered'
                              : 'Processing'}
                          </label>
                          <input
                            type="checkbox"
                            className={
                              'form-check-input ' +
                              `${
                                courseRegistrationDetails?.status ===
                                  'registered' && 'bg-success'
                              }`
                            }
                            id="statusSwitch"
                            style={{
                              border:
                                courseRegistrationDetails?.status ===
                                  'registered' && 'none',
                            }}
                            onChange={() => {
                              handleStatusChange(
                                courseRegistrationDetails?.status ===
                                  'registered'
                                  ? false
                                  : true,
                                courseRegistrationDetails?._id,
                              )
                            }}
                            checked={
                              courseRegistrationDetails?.status === 'registered'
                            }
                          />
                        </div> */}
                      {/* <Row>
                        {courseRegistrationDetails &&
                          courseRegistrationDetails?.courseProvider &&
                          courseRegistrationDetails?.courseProvider
                            ?.courseFormData &&
                          courseRegistrationDetails?.courseProvider
                            ?.courseFormData?.bannerImage[0] && (
                            <>
                              <h4>Banner Image</h4>
                              <img
                                src={
                                  courseRegistrationDetails?.courseProvider
                                    ?.courseFormData?.bannerImage[0]?.url
                                }
                                alt=""
                              />
                            </>
                          )}
                        <div className="mt-2">
                          <h4>
                            {
                              courseRegistrationDetails?.courseProvider
                                ?.courseFormData?.title
                            }
                          </h4>
                          <p>
                            {
                              courseRegistrationDetails?.courseProvider
                                ?.courseFormData?.description
                            }
                          </p>
                        </div>
                        {newInputData?.map((item, key) => (
                          <div key={key} className="d-flex align-items-center">
                            <p className="m-0 me-2">{item?.title}</p>
                            <span>{item?.value && ' : '}</span>
                            <p className="m-0 mx-3">{item?.value}</p>
                          </div>
                        ))}
                      </Row> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  {courseRegistrationDetails?.order && (
                    <Card>
                      <CardBody>
                        Order Details:
                        <div className="d-flex mt-2">
                          <h6> Order Id:</h6>
                          {role === 'admin' ? (
                            <Link
                              to={`/order/${courseRegistrationDetails?.order?._id}`}
                            >
                              <h6 className="mx-2 text-primary">
                                {' '}
                                {courseRegistrationDetails?.order?.orderId}
                              </h6>
                            </Link>
                          ) : (
                            <h6 className="mx-2 text-primary">
                              {' '}
                              {courseRegistrationDetails?.order?.orderId}
                            </h6>
                          )}
                        </div>
                        <div className="d-flex">
                          <h6>Order Amount: </h6>
                          <h6 className="mx-2">
                            {courseRegistrationDetails?.order?.totalPrice}{' '}
                          </h6>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {courseRegistrationDetails?.product && (
                    <Card>
                      <CardBody>
                        Product Details:
                        <div className="d-flex">
                          <TabContent>
                            {courseRegistrationDetails &&
                              courseRegistrationDetails?.product &&
                              courseRegistrationDetails?.product?.images &&
                              courseRegistrationDetails?.product?.images?.map(
                                (image, index) => (
                                  <TabPane key={index}>
                                    <div>
                                      <img
                                        src={image?.url}
                                        alt=""
                                        id="productimg"
                                        className="img-fluid mx-auto d-block"
                                      />
                                    </div>
                                  </TabPane>
                                ),
                              )}
                          </TabContent>
                        </div>
                        <div className="d-flex mt-2">
                          {role === 'admin' ? (
                            <Link
                              to={`/product/update/${courseRegistrationDetails?.product?._id}`}
                            >
                              <h6 className="mx-2 text-primary">
                                {' '}
                                {
                                  courseRegistrationDetails?.product
                                    ?.primaryLang?.name
                                }
                              </h6>
                            </Link>
                          ) : (
                            <h6 className="mx-2 text-primary">
                              {' '}
                              {
                                courseRegistrationDetails?.product?.primaryLang
                                  ?.name
                              }
                            </h6>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CourseRegistrationDetail
