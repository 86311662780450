import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  NavItem,
  NavLink,
  CardBody,
  Col,
  Container,
  Nav,
  Row,
} from 'reactstrap'
import {
  getPromoterProductDetails,
  getPromoterProfileDetails,
  whatsappApi,
} from 'store/actions'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Markup } from 'interweave'
import { Notification } from 'components/Common/Notification'
import MetaTag from 'components/Common/Meta-tag'
import noImage from '../../../assets/images/Defualt/noimage.png'

function PromoterProductDetails() {
  const dispatch = useDispatch()
  const params = useParams()

  const { products, promoterProfile } = useSelector(state => ({
    products: state?.Promoter?.promoterProductDetails,
    promoterProfile: state?.Promoter?.promoterProfile,
  }))

  useEffect(() => {
    dispatch(getPromoterProductDetails(params?.id))
    dispatch(getPromoterProfileDetails())
  }, [dispatch])

  const [imageShow, setImageShow] = useState(
    products?.images?.[0]?.url ? products?.images?.[0]?.url : noImage,
  )

  useEffect(() => {
    setImageShow(products?.images?.[0]?.url)
  }, [products])

  //

  const handleCopy = () => {
    const removeSpace = products?.primaryLang?.name?.replace(/ /g, '-')
    const text = `https://grampoo.com/products/${removeSpace}?ref_id=${promoterProfile?.ref_id}`
    navigator.clipboard?.writeText(text)
    Notification({
      type: 'success',
      message: 'URL Copied to clipboard',
      title: '',
    })
  }
  return (
    <div>
      <MetaTag title="Products/Product-Details" />

      <div className="page-content ">
        <Breadcrumbs
          title="Promoter Dashboard"
          breadcrumbItem="Product Details"
        />
        <Container fluid>
          <div className="container-fluid ">
            <Card>
              <CardBody>
                <>
                  <Row>
                    <Col xl="6">
                      <div className="product-detai-imgs">
                        <Row>
                          <Col md="2" xs="3">
                            <Nav className="flex-column" pills>
                              <NavItem>
                                <NavLink>
                                  <img
                                    src={
                                      products?.images?.[0].url
                                        ? products?.images?.[0]?.url
                                        : noImage
                                    }
                                    alt=""
                                    onClick={() => {
                                      setImageShow(products?.images?.[0].url)
                                    }}
                                    className="img-fluid mx-auto d-block rounded"
                                  />
                                </NavLink>
                              </NavItem>{' '}
                              <NavItem>
                                <NavLink>
                                  <img
                                    src={products?.images?.[1]?.url}
                                    alt=""
                                    onClick={() => {
                                      setImageShow(
                                        products?.images?.[1]?.url
                                          ? products?.images?.[1]?.url
                                          : '',
                                      )
                                    }}
                                    className="img-fluid mx-auto d-block rounded"
                                  />
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink>
                                  <img
                                    src={products?.images?.[2]?.url}
                                    alt=""
                                    onClick={() => {
                                      setImageShow(
                                        products?.images?.[2]?.url
                                          ? products?.images?.[2]?.url
                                          : '',
                                      )
                                    }}
                                    className="img-fluid mx-auto d-block rounded"
                                  />
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Col>
                          <Col md={{ size: 7, offset: 1 }} xs="9">
                            <div>
                              <img
                                src={imageShow ? imageShow : noImage}
                                alt=""
                                id="expandedImg1"
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <Link to="#" className="text-primary">
                          {products?.category}
                        </Link>

                        <p className="text-muted mb-4 h3">
                          {products?.primaryLang?.name}
                        </p>

                        {!!products.isOffer && (
                          <h6 className="text-success text-uppercase">
                            {products.offer} % Off
                          </h6>
                        )}
                        <h5 className="mb-4">
                          <span className="text-muted me-2">
                            <del className="text-danger font-size-14">
                              ₹{products.price}{' '}
                            </del>
                          </span>{' '}
                          <b className="text-success font-size-20">
                            ₹{products.offerPrice}{' '}
                          </b>
                        </h5>
                        <p className="text-muted mb-4">
                          <Markup
                            content={products?.primaryLang?.description}
                          />
                        </p>
                        <div className="d-flex algin-items-center ">
                          <Button
                            type="button"
                            className="px-3"
                            color="light"
                            onClick={() => handleCopy()}
                          >
                            Copy Url
                          </Button>
                          <Button
                            type="button"
                            className="px-3 mx-2"
                            color="success"
                            onClick={() => dispatch(whatsappApi(products?._id))}
                          >
                            Get it on whatsapp
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PromoterProductDetails
