import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledTooltip,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'

import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

import classnames from 'classnames'

//actions
import {
  getAllChainPromoters,
  getAllSupplyChainTransactions,
  getPromoterTransaction,
  getPromoters,
} from 'store/actions'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { FaList } from 'react-icons/fa'
import { RiTableFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import ReactDrawer from 'react-drawer'
import { CurrencySwitch } from 'hooks/Currency'
import { useWindowSize } from 'hooks/useWindowSize'

const PromoterTransactions = ({ promoterId, isPromoterDashboard }) => {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()
  const width = useWindowSize()

  const role = sessionStorage.getItem('role')

  const {
    supplyChainTransactions,
    loading,
    allPromoters,
    promoterLoad,
    chainPromoters,
    chainPromoterLoad,
  } = useSelector(state => ({
    supplyChainTransactions: state.SupplyChain.supplyChainTransactions,
    loading: state.SupplyChain.loading,
    allPromoters: state?.Users?.promotersList,
    promoterLoad: state.Users?.promoterLoad,
    chainPromoters: state.SupplyChain.chainPromoters,
    chainPromoterLoad: state.SupplyChain.loading,
  }))

  const isMobile = width?.width <= 425

  const [layout, setLayout] = useState('table')
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState('right')
  const [totalItemLimit, setTotalItemLimit] = useState(30)
  const [page, setPage] = useState(1)
  const [category, setCategory] = useState('')
  const [transactionType, setTransactionType] = useState('')
  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: '',
    to: '',
  })
  const [promoterSelect, setPromoterSelect] = useState({
    title: 'Search Promoter',
  })
  const [chainPromoterSelect, setChainPromoterSelect] = useState({
    title: 'Search Chain Promoter',
  })

  useEffect(() => {
    const credentials = {
      page,
      category,
      limit: isPromoterDashboard ? 5 : totalItemLimit,
      transactionType,
      promoter: promoterSelect.id,
      chainPromoter: chainPromoterSelect.id,
      date: finalDateFormatted,
    }
    if (promoterId) {
      dispatch(getPromoterTransaction(promoterId))
    } else {
      dispatch(getAllSupplyChainTransactions(credentials))
    }
  }, [
    dispatch,
    page,
    category,
    totalItemLimit,
    transactionType,
    promoterSelect,
    chainPromoterSelect,
    finalDateFormatted,
    promoterId,
  ])

  const totalPages = Math.ceil(supplyChainTransactions?.total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    if (promoterId) {
      setPromoterSelect({
        ...promoterSelect,
        id: promoterId,
      })
    }
  }, [promoterId])

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
    },
    {
      dataField: 'particulars',
      text: 'Particulars ',
      sort: true,
    },
    {
      dataField: 'category',
      text: 'Category',
      sort: true,
    },

    {
      dataField: 'orderId',
      text: 'Order Id',
      sort: true,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
    },
    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
    },
  ]

  const handleColumns = () => {
    if (promoterId) {
      return columns?.filter(i => i.dataField !== 'phone')
    } else {
      return columns
    }
  }
  const productData = map(
    supplyChainTransactions?.promoterTransactions,
    item => ({
      ...item,
      date: moment(item.date).format('lll'),
      amount: `${currency} ${item.amount}`,
      phone: (
        <>
          <Link to={`/promoter/${item?.promoter?._id}`} id="promoterName">
            {item?.promoter?.phone}
          </Link>
          {item?.promoter?.name && (
            <UncontrolledTooltip placement="top" target="promoterName">
              {item?.promoter?.name}
            </UncontrolledTooltip>
          )}
        </>
      ),
      orderId: (
        <Link to={`/order/${item?.order?._id}`}>{item?.order?.orderId}</Link>
      ),
    }),
  )

  // Select All Button operation
  const selectRow = {
    mode: 'checkbox',
  }
  const listChange = sortValue => {
    setCategory(sortValue)
  }

  const tableTitles = [
    {
      title: 'All',
      value: '',
    },
    {
      title: 'Lead Bonus',
      value: 'Lead Bonus',
    },

    {
      title: 'Growth Reward',
      value: 'Growth Reward',
    },
    {
      title: 'Withdrawal',
      value: 'Withdrawal',
    },
  ]

  //promoterSelect
  const [promoterSearch, setPromoterSearch] = useState('')
  const debouncePromoterSearch = debounce(
    value => setPromoterSearch(value),
    600,
  )

  useEffect(() => {
    if (role === 'admin') {
      dispatch(getPromoters(page, promoterSearch))
    }
  }, [dispatch, promoterSearch, role])

  const handlePromoterEnters = textEntered => {
    debouncePromoterSearch(textEntered)
  }

  function handlerPromoterFinalValue(event) {
    const promoter = {
      ...promoterSelect,
      title: event.label,
      id: event.value,
    }
    setPromoterSelect(promoter)
  }

  const promoterOptions = [
    {
      label: 'none',
      id: '',
    },
    {
      options: (allPromoters?.total >= 1 ? allPromoters?.promoters : [])?.map(
        (result, index) => ({
          key: index,
          label: `${result?.phone} - ${result?.name || ''}`,
          value: result?._id,
        }),
      ),
    },
  ]

  //chainPromoterSelect
  const [chainPromoterSearch, setChainPromoterSearch] = useState('')
  const debounceChainPromoterSearch = debounce(
    value => setChainPromoterSearch(value),
    600,
  )

  useEffect(() => {
    if (!isPromoterDashboard) {
      const credentials = {
        page: 1,
        sort: '',
        limit: 10,
        searchText: chainPromoterSearch,
        supplyChain: '',
        promoter: '',
      }
      dispatch(getAllChainPromoters(credentials))
    }
  }, [dispatch, chainPromoterSearch])

  const handleChainPromoterEnters = textEntered => {
    debounceChainPromoterSearch(textEntered)
  }

  function handlerChainPromoterFinalValue(event) {
    const chainPromoter = {
      ...chainPromoterSelect,
      title: event.label,
      id: event.value,
    }
    setChainPromoterSelect(chainPromoter)
  }

  const chainPromoterOptions = [
    {
      label: 'none',
      id: '',
    },
    {
      options: (chainPromoters?.total >= 1
        ? chainPromoters?.chainPromoters
        : []
      )?.map((result, index) => ({
        key: index,
        label: result?.ref_id,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    const currentLimit = localStorage.getItem('promoterTransactionLimit')
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem('promoterTransactionLimit', e.target.value)
    }
  }

  const onDrawerClose = () => {
    setOpen(false)
  }
  const toggleTopDrawer = () => {
    setPosition('right')
    setOpen(!open)
    setLayout('list')
    localStorage.setItem('transactionLayout', 'list')
  }

  useEffect(() => {
    const newLayout = localStorage.getItem('transactionLayout')
    if (newLayout && newLayout !== undefined) {
      setLayout(newLayout || 'table')
    }
  }, [])

  const headerFilter = (
    <>
      <Col
        sm="12"
        className="d-flex align-items-center justify-content-between filter-header-1 "
      >
        <div
          className="btn-group mt-xl-0"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          {map(tableTitles, (head, key) => (
            <Fragment key={key}>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id={head.value + key}
                autoComplete="off"
                checked={category === head.value}
                onChange={() => listChange(head?.value || '')}
              />
              <label
                className="btn mb-0 btn-outline-info d-flex align-items-center w-100"
                htmlFor={head.value + key}
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {head?.title}
                <Badge
                  className={`font-size-12 ms-2 ${
                    category === head.value
                      ? 'badge-soft-warning'
                      : 'badge-soft-light'
                  }`}
                  pill
                >
                  {head?.count}
                </Badge>
              </label>
            </Fragment>
          ))}
        </div>
        <div className="d-flex sortWrapper mx-2 me-3 align-items-center ">
          <p className="mb-0 pb-0 mx-1" style={{ whiteSpace: 'nowrap' }}>
            Sort By Date :
          </p>
          <AvForm>
            <div className="d-flex date_wrapper">
              <div className="d-flex dateRange1 align-items-start position-relative">
                <AvField
                  name="dateRange"
                  type="date"
                  validate={{
                    dateRange: {
                      start: {
                        value: -5,
                        units: 'years',
                      },
                      end: { value: 5, units: 'years' },
                    },
                  }}
                  onChange={e =>
                    setFinalDateFormatted({
                      ...finalDateFormatted,
                      from: e.target.value,
                    })
                  }
                  value={finalDateFormatted?.from}
                />
                <button
                  onClick={() =>
                    setFinalDateFormatted({
                      ...finalDateFormatted,
                      from: '',
                    })
                  }
                  type="button"
                  className="btn btn-white editable-cancel date_close btn-sm"
                >
                  <i className="mdi mdi-close text-danger "></i>
                </button>
              </div>
              <div className="d-flex dateRange1 align-items-start position-relative">
                <AvField
                  name="dateRange"
                  className="mx-1"
                  type="date"
                  validate={{
                    dateRange: {
                      start: {
                        value: -5,
                        units: 'years',
                      },
                      end: { value: 5, units: 'years' },
                    },
                  }}
                  onChange={e =>
                    setFinalDateFormatted({
                      ...finalDateFormatted,
                      to: e.target.value,
                    })
                  }
                  value={finalDateFormatted?.to}
                />
                <button
                  onClick={() =>
                    setFinalDateFormatted({
                      ...finalDateFormatted,
                      to: '',
                    })
                  }
                  type="button"
                  className="btn btn-white editable-cancel date_close btn-sm"
                >
                  <i className="mdi mdi-close text-danger "></i>
                </button>
              </div>
            </div>
          </AvForm>
        </div>
      </Col>
      <Col sm="12" className="filter-header-2">
        <div className="d-flex align-items-center justify-content-start mt-3 filter-subheader-2">
          <AvForm className="form-horizontal mx-2 d-flex align-items-center">
            <div className="mx-1">
              <span className="">
                Show
                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
              </span>
            </div>
            <div className="" style={{ width: '50px' }}>
              <AvField
                name="pageLimit"
                className="form-control"
                value={totalItemLimit}
                onChange={e => handlePageLimit(e)}
                type="text"
                required
              />
            </div>
          </AvForm>

          {promoterId === undefined && (
            <>
              {role === 'admin' && (
                <FormGroup className="w-25 me-3">
                  <div className="ajax-select mt-lg-0 select2-container">
                    <Select
                      onInputChange={handlePromoterEnters}
                      value={promoterSelect?.title}
                      placeholder={promoterSelect?.title}
                      onChange={handlerPromoterFinalValue}
                      options={promoterOptions}
                      classNamePrefix="select2-selection"
                      isLoading={promoterLoad}
                    />
                  </div>
                </FormGroup>
              )}

              <FormGroup className="w-25 me-3">
                <div className="ajax-select mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleChainPromoterEnters}
                    value={chainPromoterSelect?.title}
                    placeholder={chainPromoterSelect?.title}
                    onChange={handlerChainPromoterFinalValue}
                    options={chainPromoterOptions}
                    classNamePrefix="select2-selection"
                    isLoading={chainPromoterLoad}
                  />
                </div>
              </FormGroup>
            </>
          )}
        </div>
      </Col>
    </>
  )

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          {!isPromoterDashboard && (
            <>
              {promoterId === undefined && layout === 'table' && (
                <Card>
                  <CardBody>
                    <Row className="mb-2">{headerFilter}</Row>
                  </CardBody>
                </Card>
              )}
            </>
          )}
          <Card style={{ minHeight: '310px' }}>
            <CardBody>
              {!isPromoterDashboard && (
                <>
                  {promoterId === undefined && (
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <Nav tabs className="nav-tabs-custom  nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: transactionType === '',
                            })}
                            onClick={() => {
                              setTransactionType('')
                            }}
                          >
                            {/* <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span> */}
                            {/* <span className="d-none d-sm-block">All</span> */}
                            <span>All</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: transactionType === 'credit',
                            })}
                            onClick={() => {
                              setTransactionType('credit')
                            }}
                          >
                            {/* <span className="d-block d-sm-none">
                              <i className="fas fa-cog"></i>
                            </span> */}
                            {/* <span className="d-none d-sm-block">Credit</span> */}
                            <span>Credit</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: transactionType === 'debit',
                            })}
                            onClick={() => {
                              setTransactionType('debit')
                            }}
                          >
                            {/* <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Debit</span> */}
                            <span>Debit</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <div className="d-flex align-items-center">
                        {!isMobile && (
                          <div>
                            <UncontrolledTooltip
                              placement="bottom"
                              target={'table-view-option'}
                            >
                              Table view
                            </UncontrolledTooltip>
                            <Button
                              color={layout === 'table' ? 'warning' : 'light'}
                              className="btn-rounded ms-4 "
                              id="table-view-option"
                              onClick={() => {
                                setLayout('table')
                                localStorage.setItem(
                                  'transactionLayout',
                                  'table',
                                )
                              }}
                            >
                              <RiTableFill />
                            </Button>
                          </div>
                        )}
                        <div>
                          <UncontrolledTooltip
                            placement="bottom"
                            target={'list-view-option'}
                          >
                            List view
                          </UncontrolledTooltip>
                          <Button
                            color={layout === 'list' ? 'warning' : 'light'}
                            className="btn-rounded ms-2 position-relative"
                            id="list-view-option"
                            onClick={toggleTopDrawer}
                          >
                            <FaList />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={handleColumns()}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {supplyChainTransactions?.total !== 0 ? (
                          <>
                            <Row>
                              <p>
                                Total Transactions :&nbsp;
                                {supplyChainTransactions?.total || 0}{' '}
                              </p>

                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    selectRow={selectRow}
                                    classes={
                                      'table align-middle table-nowrap custom-padding md table-striped'
                                    }
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {!isPromoterDashboard && (
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            )}
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Transactions !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {layout === 'list' && (
        <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
          <SimpleBar style={{ height: '900px', boxShadow: 'none' }}>
            <div data-simplebar className="h-100">
              <div className="rightbar-title px-3 py-4">
                <Link
                  to="#"
                  onClick={onDrawerClose}
                  className="right-bar-toggle float-end"
                >
                  <i className="mdi mdi-close noti-icon" />
                </Link>
                <p className="m-0">Filter Options</p>
              </div>
              <hr className="my-0" />
              <div className="transaction-option-sidebar">{headerFilter}</div>
            </div>
          </SimpleBar>
        </ReactDrawer>
      )}
    </React.Fragment>
  )
}

export default PromoterTransactions

PromoterTransactions.propTypes = {
  promoterId: PropTypes.string,
  isPromoterDashboard: PropTypes.bool,
}
