/* CourseRegistrations - This line cannot be edited or removed */
export const GET_COURSE_REGISTRATIONS = 'GET_COURSE_REGISTRATIONS'
export const GET_COURSE_REGISTRATIONS_SUCCESS =
  'GET_COURSE_REGISTRATIONS_SUCCESS'
export const GET_COURSE_REGISTRATIONS_FAIL = 'GET_COURSE_REGISTRATIONS_FAIL'

/* CourseRegistration_DETAILS */
export const GET_COURSE_REGISTRATION_DETAILS = 'GET_COURSE_REGISTRATION_DETAILS'
export const GET_COURSE_REGISTRATION_DETAILS_SUCCESS =
  'GET_COURSE_REGISTRATION_DETAILS_SUCCESS'
export const GET_COURSE_REGISTRATION_DETAILS_FAIL =
  'GET_COURSE_REGISTRATION_DETAILS_FAIL'

/* Edit CourseRegistration */
export const UPDATE_COURSE_REGISTRATION = 'UPDATE_COURSE_REGISTRATION'
export const UPDATE_COURSE_REGISTRATION_SUCCESS =
  'UPDATE_COURSE_REGISTRATION_SUCCESS'
export const UPDATE_COURSE_REGISTRATION_FAIL = 'UPDATE_COURSE_REGISTRATION_FAIL'
