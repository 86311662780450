import {
  GET_COURSE_REGISTRATIONS,
  GET_COURSE_REGISTRATIONS_SUCCESS,
  GET_COURSE_REGISTRATIONS_FAIL,
  GET_COURSE_REGISTRATION_DETAILS,
  GET_COURSE_REGISTRATION_DETAILS_SUCCESS,
  GET_COURSE_REGISTRATION_DETAILS_FAIL,
  UPDATE_COURSE_REGISTRATION,
  UPDATE_COURSE_REGISTRATION_SUCCESS,
  UPDATE_COURSE_REGISTRATION_FAIL,
} from './actionTypes'

const INIT_STATE = {
  courseRegistrations: {
    courseRegistrations: [],
    total: '',
    page: '',
  },
  courseRegistrationDetails: {},
  error: {},
  loading: false,
}

const CourseRegistration = (state = INIT_STATE, action) => {
  switch (action.type) {
    // CourseRegistration - This line cannot be edited or removed
    case GET_COURSE_REGISTRATIONS:
    case GET_COURSE_REGISTRATION_DETAILS:
    case UPDATE_COURSE_REGISTRATION:
      return {
        ...state,
        loading: true,
      }

    case GET_COURSE_REGISTRATIONS_FAIL:
    case GET_COURSE_REGISTRATION_DETAILS_FAIL:
    case UPDATE_COURSE_REGISTRATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COURSE_REGISTRATIONS_SUCCESS:
      return {
        ...state,
        courseRegistrations: action.payload,
        error: {},
        loading: false,
      }

    case GET_COURSE_REGISTRATION_DETAILS_SUCCESS:
      return {
        ...state,
        courseRegistrationDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_COURSE_REGISTRATION_SUCCESS:
      return {
        ...state,
        courseRegistrations: {
          ...state.courseRegistrations,
          courseRegistrations:
            state.courseRegistrations.courseRegistrations.map(
              courseRegistration =>
                courseRegistration._id === action.payload._id
                  ? { ...courseRegistration, ...action.payload }
                  : courseRegistration,
            ),
        },
        courseRegistrationDetails: action.payload,
        loading: false,
        error: {},
      }

    default:
      return state
  }
}

export default CourseRegistration
