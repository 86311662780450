export const sidebarData = [
  {
    routeLink: '/#',
    icon: 'bx bx-home-circle',
    title: 'Dashboard',
  },
  // {
  //   routeLink: '/promoter-dashboard',
  //   icon: 'bx bxs-user-voice',
  //   title: 'Promoters',
  //   subTitles: [
  //     { title: "All Promoters", routeLink: "/promoters" },
  //     { title: "All Page Views", routeLink: "/all-page-view" },
  //     { title: "All Supply Chain", routeLink: "/supply-chain" },
  //     { title: "Chain Promoter", routeLink: "/all-page-view" },
  //     { title: "Transations", routeLink: "/all-earnings" },
  //     { title: "luckyDraws", routeLink: "/formatted-earnings" },
  //   ],
  // },
  {
    routeLink: '/orders',
    icon: 'bx bxs-shopping-bags',
    title: 'Orders',
  },
  {
    routeLink: '/products',
    icon: 'bx bxs-package',
    title: 'Products',
  },

  {
    routeLink: '/stockUpdate',
    icon: 'bx bx-store-alt',
    title: 'Stock & Offer',
  },
  {
    routeLink: '/#',
    icon: '',
    title: 'Site Setup',
    isSeparator: true,
  },

  {
    routeLink: '/classifications',
    icon: 'bx bxs-collection',
    title: 'Categories',
  },
  {
    routeLink: '/collections',
    icon: 'bx bxs-extension',
    title: 'Collections',
  },

  {
    routeLink: '/coupons',
    icon: 'bx bxs-coupon',
    title: 'Coupons',
  },
  {
    routeLink: '/notifications',
    icon: 'bx bxs-rocket',
    title: 'Notification',
  },

  {
    routeLink: '/#',
    icon: '',
    title: 'Theme Setup',
    isSeparator: true,
  },
  {
    routeLink: '/banners',
    icon: 'bx bxs-image',
    title: 'Banners',
  },
  {
    routeLink: '/groups',
    icon: 'fas fa-layer-group',
    title: 'Groups',
  },
  {
    routeLink: '/pages/all',
    icon: 'bx bx-food-menu',
    title: 'Pages',
  },

  {
    routeLink: '/#',
    icon: '',
    title: 'financial',
    isSeparator: true,
  },
  {
    routeLink: '/revenues',
    icon: 'bx bxs-landmark',
    title: 'Revenue',
  },
  // {
  //   routeLink: '/deductions',
  //   icon: 'bx bx-wallet-alt',
  //   title: 'Deductions',
  // },
  {
    routeLink: '/transactions',
    isPhonePe: true,
    icon: 'bx bx-transfer',
    title: 'Transactions',
  },

  {
    routeLink: '/performance/product',
    icon: 'bx bxs-rocket',
    title: 'Performance',
  },

  {
    routeLink: '/#',
    icon: '',
    title: 'Feedback',
    isSeparator: true,
  },

  {
    routeLink: '/users',
    icon: 'bx bxs-user',
    title: 'Users',
  },

  {
    routeLink: '/reviews',
    icon: 'bx bxs-award',
    title: 'Reviews',
  },
  {
    routeLink: '/blogs',
    icon: 'bx bx-file',
    title: 'Blogs',
  },

  // {
  //   routeLink: '/#',
  //   icon: '',
  //   title: 'Extras',
  //   isSeparator: true,
  // },
  // {
  //   routeLink: "/#",
  //   icon: "bx bxs-user",
  //   title: "Supply Chain",
  //   subTitles: [
  //     { title: "All Supply Chain", routeLink: "/supply-chain" },
  //     { title: "Chain Promoter", routeLink: "/all-page-view" },
  //     { title: "Transations", routeLink: "/all-earnings" },
  //     { title: "luckyDraws", routeLink: "/formatted-earnings" },
  //   ],
  // },
  // {
  //   routeLink: '/courseProviders',
  //   icon: 'bx bx-book',
  //   title: 'CourseProviders',
  // },
  // {
  //   routeLink: '/courseRegistrations',
  //   icon: 'bx bxs-book-content',
  //   title: 'Course Registrations',
  // },

  {
    routeLink: '/#',
    icon: '',
    title: 'Administration',
    isSeparator: true,
  },

  {
    routeLink: '/super-admin/settings',
    icon: 'bx bxs-check-shield',
    title: 'Admin Settings',
  },
]

export const promoterDashboard = [
  { title: 'Dashboard', routeLink: '/dashboard', icon: 'bx bxs-home' },

  // {
  //   title: "Earnings",
  //   routeLink: "/promoter-earnings",
  //   icon: "bx bx-dollar",
  // },

  {
    title: 'Sources',
    routeLink: '/chain-promoter',
    icon: 'bx bxs-user-voice',
  },
  {
    title: 'Transactions',

    routeLink: '/promoter-transactions',
    icon: 'bx bx-transfer',
  },

  {
    title: 'Withdrawals',
    routeLink: '/withdrawal-requests',
    icon: 'bx bxs-coupon',
  },
  {
    title: 'Products',
    routeLink: '/promoter/products',
    icon: 'bx bxs-package',
  },
  {
    title: 'Page Views',
    routeLink: '/promoter-page-view',
    icon: 'bx bx-show-alt',
  },
]

export const courseProviderDashboard = [
  { title: 'Dashboard', routeLink: '/dashboard', icon: 'bx bxs-home' },

  // {
  //   routeLink: '/courseRegistrations',
  //   icon: 'bx bx-book',
  //   title: 'Course Registrations',
  // },
]
