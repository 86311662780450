import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Label,
  UncontrolledTooltip,
} from 'reactstrap'

//i18n
import { withTranslation } from 'react-i18next'
// Redux
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { getCourseProviderProfile } from 'store/actions'

const ProfileMenu = props => {
  const dispatch = useDispatch()
  const { user, promoterProfile, courseProviderProfile } = useSelector(
    state => ({
      user: state.Login.user,
      promoterProfile: state.Promoter.promoterProfile,
      courseProviderProfile: state.CourseProvider.courseProviderProfile,
    }),
  )

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setUsername] = useState()

  const role = user?.role ? user?.role : sessionStorage.getItem('role')

  useEffect(() => {
    if (role === 'admin') {
      setUsername(role)
    } else if (role === 'promoter') {
      setUsername(promoterProfile?.name)
    } else {
      setUsername(courseProviderProfile?.name)
    }
  }, [user?.role, promoterProfile, courseProviderProfile, role])

  useEffect(() => {
    if (role === 'courseProvider') {
      dispatch(getCourseProviderProfile())
    }
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="d-flex flex-column">
            <Label
              id="roleOfUserId"
              className="m-0 p-0 font-size-11 text-muted"
              style={{ textTransform: 'capitalize', cursor: 'pointer' }}
            >
              {role}
            </Label>
            <UncontrolledTooltip target={'roleOfUserId'}>
              Role
            </UncontrolledTooltip>

            <Label
              id="usernameOfUserId"
              className="m-0 p-0 text-black"
              style={{ textTransform: 'capitalize', cursor: 'pointer' }}
            >
              {username}
            </Label>
            <UncontrolledTooltip target={'usernameOfUserId'}>
              User Name
            </UncontrolledTooltip>
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
          <Link
            to={{ pathname: '/logout', state: { isLocal: true } }}
            className="dropdown-item"
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Logout On This Device')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
)
