import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'
import {
  courseProviderDashboard,
  promoterDashboard,
  sidebarData,
} from './sidebarData'
import { useKeyPress } from 'hooks/useKeyPress'
import { useDispatch, useSelector } from 'react-redux'
import { showSidebarAuthorized } from 'store/actions'
import { useSiteVersion } from 'hooks/useSiteVersion'
import { map } from 'lodash'

const SidebarContent = props => {
  const ref = useRef()
  const dispatch = useDispatch()
  const { currentVersion } = useSiteVersion()
  const { sideBarAuthorized, paymentType } = useSelector(state => ({
    sideBarAuthorized: state.Layout.sideBarAuthorized,
    paymentType: state?.Settings.settings?.payment?.paymentGateway,
  }))

  const role = sessionStorage.getItem('role')

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu('#side-menu')
      let matchingMenuItem = null
      const ul = document.getElementById('side-menu')
      const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const version = currentVersion

  const administration = ['Administration', 'Admin Settings']

  const filterBasicVersion = [
    ...administration,
    // "Collections",
    'Coupon',
    'Stock & Offer',
    'Revenue',
    'Blogs',
    'Pages',
    'Reviews',
    'Performance',
  ]
  const filterPremiumVersion = [...administration]
  const filterAdvancedVersion = [...administration]

  const basicSidebar = sidebarData?.filter(
    item => !filterBasicVersion.includes(item.title),
  )
  const premiumSidebar = sidebarData?.filter(
    item => !filterPremiumVersion.includes(item.title),
  )
  const advancedSidebar = sidebarData?.filter(
    item => !filterAdvancedVersion.includes(item.title),
  )

  const handleSiteVersion = () => {
    if (sideBarAuthorized === true) {
      return sidebarData
    } else {
      switch (version) {
        case 'basic':
          return basicSidebar
        case 'premium':
          return premiumSidebar
        case 'advanced':
          return advancedSidebar
        default:
          return basicSidebar
      }
    }
  }

  const handleVersion = () => {
    switch (version) {
      case 'basic':
        return filterBasicVersion
      case 'premium':
        return filterPremiumVersion
      case 'advanced':
        return filterAdvancedVersion
      default:
        return filterBasicVersion
    }
  }

  const [myCombination, setMyCombination] = useState('')

  const onKeyPress = event => {
    setMyCombination(myCombination + event.key)
  }

  useKeyPress(['Control', 'Shift', 'o', 's', 'p', 'e', 'r', 'b'], onKeyPress)

  useEffect(() => {
    if (myCombination === 'ControlShiftosperb') {
      dispatch(showSidebarAuthorized(true))
      setMyCombination('')
    }
  }, [myCombination])

  useEffect(() => {
    if (sideBarAuthorized === true && myCombination === 'ControlShift') {
      dispatch(showSidebarAuthorized(false))
      setMyCombination('')
    }
  }, [myCombination, sideBarAuthorized])

  function sidebarProtected() {
    let sidebar = []
    switch (role) {
      case 'admin':
        sidebar = handleSiteVersion()
        break
      case 'promoter':
        sidebar = promoterDashboard
        break
      case 'courseProvider':
        sidebar = courseProviderDashboard
        break
      default:
        sidebar = []
        break
    }

    return sidebar
  }

  const sidebarWithOutTransation = sidebarProtected()?.filter(
    item => item?.isPhonePe !== true,
  )

  const handleSideBarProtected = () => {
    if (paymentType === 'PhonePe') {
      return sidebarProtected()
    } else {
      return sidebarWithOutTransation
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {handleSideBarProtected()?.map((item, key) => (
              <Fragment key={key}>
                {item.isSeparator ? (
                  <li className="menu-title">{props.t(`${item['title']}`)}</li>
                ) : (
                  <li>
                    <Link
                      to={item.routeLink}
                      className={
                        handleVersion()?.includes(item.title)
                          ? 'text-primary'
                          : ''
                      }
                    >
                      <i
                        className={`${item.icon} ${
                          handleVersion()?.includes(item.title) &&
                          'text-primary'
                        }`}
                      ></i>
                      <span>{props.t(`${item['title']}`)}</span>
                    </Link>

                    {item?.subTitles?.length >= 1 && (
                      <ul className="sub-menu" aria-expanded="false">
                        {map(item?.subTitles, (title, index1) => (
                          <li key={index1}>
                            <Link to={title?.routeLink}>
                              {props.t(`${title?.title}`)}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
